import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { MinimumEntryFieldsFragment } from '@/fragments';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import {
  createDecoratedEntry,
  getEntryInstances,
  sortEntryInstances,
} from '@/pages/Schedule/utils';
import { createScheduleSnippetsQueryKey } from '@/utils/queryKeys';
import type {
  DecoratedSnippet,
  DecoratedSnippetEntry,
  DecoratedSnippetInstance,
} from '../../types';
import type {
  GetSnippetsQuery,
  GetSnippetsQueryVariables,
} from './useSnippets.generated';

const ENTRIES_LIMIT = 3;

const query = gql`
  ${MinimumEntryFieldsFragment}
  query GetSnippets(
    $scheduleId: ID!
    $entriesLimit: Int
    $entriesStartDate: DateTime!
    $entriesEndDate: DateTime!
  ) {
    getSnippets(scheduleId: $scheduleId) {
      id
      name
      entries(
        startDate: $entriesStartDate
        endDate: $entriesEndDate
        limit: $entriesLimit
      ) {
        ...MinimumEntryFields
        title
        emoji
        category {
          id
          color
        }
      }
    }
  }
`;

export const useSnippets = () => {
  const { scheduleId, timeZone: scheduleTimeZone } = useScheduleContext();

  const { startDate, endDate } = useMemo(() => {
    const startDate = DateTime.local().startOf('day');
    const endDate = startDate.plus({ years: 1 });
    return { startDate, endDate };
  }, []);

  const selector = useCallback(
    (data: GetSnippetsQuery): DecoratedSnippet[] => {
      return data.getSnippets.map((snippet) => {
        const instances = snippet.entries.reduce<DecoratedSnippetInstance[]>(
          (instances, entry) => {
            const decoratedEntry: DecoratedSnippetEntry = createDecoratedEntry(
              entry,
              scheduleTimeZone
            );

            const expandedInstances = getEntryInstances(
              decoratedEntry,
              startDate,
              endDate,
              scheduleTimeZone
            ).filter(
              (instance) =>
                // Remove hidden entries and entries in the past
                !instance.isHidden && instance.startDate >= startDate
            );
            return instances.concat(expandedInstances);
          },
          []
        );

        const sortedInstances = sortEntryInstances(instances);

        return {
          ...snippet,
          // We placed a limit in the query but recurrence or multi-day
          // may have added extra instances
          entries: sortedInstances.slice(0, ENTRIES_LIMIT),
        };
      });
    },
    [startDate, endDate, scheduleTimeZone]
  );

  return useQuery({
    queryKey: createScheduleSnippetsQueryKey(scheduleId),
    select: selector,
    placeholderData: keepPreviousData,
    queryFn: () => {
      return gqlClient.request<GetSnippetsQuery, GetSnippetsQueryVariables>(
        query,
        {
          scheduleId,
          // Fetching 10 when we only want to display 3 because some of these
          // could be hidden after the client expands them
          entriesLimit: 10,
          entriesStartDate: startDate.toUTC().toISO(),
          entriesEndDate: endDate.toUTC().toISO(),
        }
      );
    },
  });
};
