import { Box, Flex, WrapItem, type WrapItemProps } from '@/ui';
import type { DecoratedSnippet } from '../types';
import { EntryItem } from './EntryItem';

type ViewProps = WrapItemProps & {
  view: DecoratedSnippet;
};

export const SnippetItem = ({ view, ...props }: ViewProps) => {
  return (
    <WrapItem
      _hover={{ bg: 'gray.50' }}
      as="button"
      borderColor="gray.200"
      borderRadius="10"
      borderWidth="1px"
      display="flex"
      flexDir="column"
      p="5"
      {...props}
    >
      <Box fontWeight="medium" mb="5" pr="4">
        {view.name}
      </Box>

      <Flex direction="column" gap="1.5" w="100%">
        {view.entries.map((instance) => (
          <EntryItem instance={instance} key={instance.id} />
        ))}
      </Flex>
    </WrapItem>
  );
};
