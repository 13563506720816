import { useTranslation } from 'react-i18next';
import {
  formatInstanceTime,
  getEntryItemStyles,
} from '@/pages/Schedule/utils/entry';
import { Box, Flex } from '@/ui';
import type { DecoratedSnippetInstance } from '../types';
import { formatInstanceDate } from '../utils';

type Props = {
  instance: DecoratedSnippetInstance;
};

export const EntryItem = ({ instance }: Props) => {
  const styles = getEntryItemStyles(instance);
  const date = formatInstanceDate(instance);
  const time = formatInstanceTime(instance);
  const { t } = useTranslation(['common']);

  return (
    <Box
      bg={styles.container.background}
      borderRadius="8"
      flex="1"
      fontSize="sm"
      key={instance.id}
      p="3"
      textAlign="left"
    >
      <Flex align="center">
        <Box flex="1" fontWeight="medium">
          {instance.title || t('common:untitled_entry')}
        </Box>
        {instance.emoji}
      </Flex>

      <Box mt="0.5" opacity="0.7">
        {date}
        {time && `, ${time}`}
      </Box>
    </Box>
  );
};
