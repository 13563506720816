import { DateTime } from 'luxon';
import { RRule } from 'rrule';
import type { DecoratedInstance } from '@/pages/Schedule/types';

export const formatInstanceDate = ({
  startDate,
  endDate,
  isOnDay,
  rule,
}: Pick<DecoratedInstance, 'startDate' | 'endDate' | 'isOnDay' | 'rule'>):
  | string
  | null => {
  const rruleOptions = RRule.parseString(rule ?? '');
  let date = startDate.toFormat('ccc, MMM d, yyyy');

  if (rruleOptions.until) {
    const until = DateTime.fromJSDate(rruleOptions.until);
    date = `${date} - ${until.toFormat('MMM d, yyyy')}`;
  } else if (
    isOnDay &&
    endDate.minus({ days: 1 }).toISO() !== startDate.toISO()
  ) {
    date = `${date} - ${endDate.minus({ days: 1 }).toFormat('MMM d, yyyy')}`;
  } else if (!isOnDay && endDate.toISODate() !== startDate.toISODate()) {
    date = `${date} - ${endDate.toFormat('MMM d, yyyy')}`;
  }

  return date;
};
