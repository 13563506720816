import { Navigate, useNavigate } from 'react-router-dom';
import { AppLoader } from '@/components/AppLoader';
import { usePageTitle } from '@/hooks/usePageTitle';
import { useTrackPageView } from '@/hooks/useTrackPageView';
import { PageHeading, ScheduleContent } from '@/pages/Schedule/components';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useSchedulePermissions } from '@/pages/Schedule/hooks';
import { Wrap, Spinner } from '@/ui';
import { EmptyState } from './components/EmptyState';
import { SnippetItem } from './components/SnippetItem';
import { useSnippets } from './hooks/useSnippets';

export const ScheduleSnippets = () => {
  const {
    data,
    isLoading: isLoadingSnippets,
    isError,
    isFetching,
  } = useSnippets();
  const { scheduleId, isLoading: isLoadingSchedule } = useScheduleContext();
  const { hasSchedulePermission } = useSchedulePermissions();
  const navigate = useNavigate();
  const isLoading = isLoadingSnippets || isLoadingSchedule;
  usePageTitle('Snippets');
  useTrackPageView('schedule:snippets');

  if (isLoading) {
    return (
      <ScheduleContent>
        <AppLoader />
      </ScheduleContent>
    );
  }

  if (!hasSchedulePermission('SNIPPET_VIEW')) {
    return <Navigate to="/" />;
  }

  if (isError) {
    return <ScheduleContent>Error</ScheduleContent>;
  }

  if (!data || !data.length) {
    return (
      <ScheduleContent>
        <EmptyState
          onAddClick={() => {
            navigate('../', { state: { openFilterPopover: true } });
          }}
        />
      </ScheduleContent>
    );
  }

  return (
    <ScheduleContent>
      <PageHeading
        mb={{ base: 6, sm: 8 }}
        rightAccessory={isFetching && <Spinner size="sm" />}
      >
        Snippets
      </PageHeading>

      <Wrap spacing="24px">
        {data.map((view) => (
          <SnippetItem
            key={view.id}
            view={view}
            w={{
              base: '100%',
              sm: 'calc(50% - 24px)',
              lg: 'calc(25% - 24px)',
            }}
            onClick={() =>
              navigate(`/snippet/${view.id}`, {
                state: { scheduleId },
              })
            }
          />
        ))}
      </Wrap>
    </ScheduleContent>
  );
};
