import { useIsTallScreen } from '@/hooks/useIsBreakpoint';
import calendar from '@/pages/Schedule/assets/happy-calendar.png';
import { useSchedulePermissions } from '@/pages/Schedule/hooks';
import { Center, Text, Image, Button } from '@/ui';

type Props = {
  onAddClick: () => void;
};

export const EmptyState = ({ onAddClick }: Props) => {
  const { hasSchedulePermission } = useSchedulePermissions();
  const isTallScreen = useIsTallScreen();

  return (
    <Center
      flex="1"
      flexDir="column"
      pb={isTallScreen ? '10%' : 0}
      textAlign="center"
    >
      <Image src={calendar} w="200px" />
      <Text fontSize={{ base: 'xl', md: '3xl' }}>Snippets</Text>
      <Text color="customgray.mid" fontSize={{ base: 'md', md: 'lg' }} mt="2">
        Snippets are a filtered version of your schedule
        <br />
        for you or to share with others!
      </Text>
      {hasSchedulePermission('SNIPPET_EDIT') && (
        <Button mt="6" onClick={onAddClick}>
          Filter your schedule to begin
        </Button>
      )}
    </Center>
  );
};
